<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('discussion.discussions') }}</h2>
    </div>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" clearable :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterDiscussions" />
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterDiscussions">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubDiscussionsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">

        <el-table-column align="center" :label="$t('discussion.sender')" min-width="250px">
          <template slot-scope="scope">
            <div class="full-width">
              <div class="half-photo">
                <div class="demo-basic--circle">
                  <div>
                    <el-avatar shape="square" :size="50" :src="scope.row.sender.avatar" />
                  </div>
                </div>
              </div>
              <div class="half-right">
                <div class="in-td">
                  <h4>{{ scope.row.sender.name }}</h4>
                  <small>{{ scope.row.sender.email }}</small>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('discussion.with')" min-width="250px">
          <template slot-scope="scope">
            <div class="full-width">
              <div class="half-photo">
                <div class="demo-basic--circle">
                  <div>
                    <el-avatar shape="square" :size="50" :src="scope.row.receipt.avatar" />
                  </div>
                </div>
              </div>
              <div class="half-right">
                <div class="in-td">
                  <h4>{{ scope.row.receipt.name }}</h4>
                  <small>{{ scope.row.receipt.email }}</small>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column v-if=" checkRole(['Admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setDiscussionStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>
        <el-table-column v-if="checkPermission(['edit discussion','hide discussion','delete discussion'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="150">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['edit discussion'])" type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="handleEditDiscussion(scope.row.id);" />
            <el-button v-if="checkPermission(['hide discussion','delete discussion'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteDiscussion(scope.row.id, scope.row.is_visible, scope.row.subject);" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getDiscussionsList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-drawer
        :title="$t('discussion.discussion')"
        :visible.sync="dialogCreateDiscussion"
        :before-close="handleCloseDiscussionCreateDraw"
        :loading="discussionCreating"
        size="30%"
      >
        <div class="content-inputs" style="padding:15px">
          <h3>{{ $t('discussion.sender') }}</h3>
          <!--=============== SENDER USER CARD ==============-->
          <subscription-card :subscription="currentDiscussion.sender" :all="true" @sendmessage="sendmessage($event, currentDiscussion.sender)" />
          <!--=============== SENDER END USER CARD ==============-->
        </div>
        <div class="content-inputs" style="padding:15px">
          <h3>{{ $t('discussion.with') }}</h3>
          <!--=============== RECEIPT USER CARD ==============-->
          <subscription-card :subscription="currentDiscussion.receipt" :all="true" @sendmessage="sendmessage($event, currentDiscussion.receipt)" />
          <!--=============== END RECEIPT USER CARD ==============-->
        </div>
      </el-drawer>

      <!--el-dialog
        :title="editing ? $t('discussion.edit') + ' ' + currentDiscussion.name : $t('discussion.AddDiscussion')"
        :visible.sync="dialogCreateDiscussion"
        :before-close="handleCloseDiscussionCreateDraw"
        :loading="discussionCreating"
        width="70%"
      >
        <div class="content-inputs">
          <el-form ref="discussionForm" :rules="rules" :model="currentDiscussion" label-position="left">
            <el-form-item :label="$t('general.processing')" prop="processing">
              <el-select v-model="currentDiscussion.processing" class="filter-item" :placeholder="$t('general.processing')" style="width: 100%;" prop="processing">
                <el-option
                  v-for="(processing, idex) in processings"
                  :key="idex"
                  :label="processing | uppercaseFirst"
                  :value="processing"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('discussion.subject')" prop="subject">
              <el-input v-model="currentDiscussion.subject" />
            </el-form-item>
            <el-form-item :label="$t('discussion.reasons')" prop="reasons">
              <el-input v-model="currentDiscussion.reasons" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateDiscussion=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="discussionCreating" @click="editing ? updateDiscussion() : createDiscussion()">
              {{ discussionCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog-->

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
    <el-dialog
      :title="$t('message.sendTo') + currentUserToMessage.name"
      :visible.sync="sendsms"
      width="40%"
    >
      <send-message :id="currentUserToMessage.user_id" :canals="[canal]" :general="generalNotif" @close="sendsms=false" />
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import subscriptionCard from '../subscriptions/components/SubscriptionCard';
import SendMessage from '../components/SendMessage';
const discussionResource = new Resource('discussions');
const subscriptionResource = new Resource('subscriptions');
export default {
  name: 'DiscussionsList',
  components: { Pagination, subscriptionCard, SendMessage },
  directives: { waves, permission, role },
  data() {
    return {
      generalNotif: false,
      sendsms: false,
      canal: '',
      currentUserToMessage: {},
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      discussionCreating: false,
      editing: false,
      activeList: 'disponibles',
      processings: this.$processingStatus,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'waiting',
      },
      currentDiscussion: {
        sender: {
          subscription: {},
        },
        receipt: {
          subscription: {},
        },
      },

      dialogCreateDiscussion: false,
      currentDiscussionId: 0,
      rules: {
        subject: [{ required: true, message: this.$t('discussion.SubjectRequired'), trigger: 'blur' }],
        reasons: [{ required: true, message: this.$t('discussion.BodyRequired'), trigger: 'blur' }],
      },
    };
  },
  computed: {
  },
  created() {
    this.resetNewDiscussion();
    this.getDiscussionsList();
  },
  methods: {
    checkPermission,
    checkRole,
    sendmessage(canal, user){
      this.currentUserToMessage = user;
      this.canal = canal;
      this.sendsms = true;
    },
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseDiscussionCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelDiscussionForm() {
      this.$refs.DiscussionCreationDrawer.closeDrawer();
    },
    getprocessingColor(processing){
      var color = 'primary';
      if (processing === 'waiting'){
        color = 'warning';
      } else if (processing === 'finished'){
        color = 'success';
      } else if (processing === 'canceled'){
        color = 'info';
      }
      return color;
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'waiting',
      };
      this.activeList = 'disponibles';
      this.handleFilterDiscussions();
    },
    async getDiscussionsList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await discussionResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
    },
    async getSubDiscussionsList(subUrl) {
      this.loading = true;
      const { data } = await discussionResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterDiscussions() {
      this.query.page = 1;
      this.getDiscussionsList();
    },
    handleCreateDiscussion() {
      this.editing = false;
      this.resetNewDiscussion();
      this.dialogCreateDiscussion = true;
      /* this.$nextTick(() => {
        this.$refs['discussionForm'].clearValidate();
      });*/
    },
    handleEditDiscussion(id){
      this.editing = true;
      this.resetNewDiscussion();
      this.currentDiscussion = this.list.find(abonne => abonne.id === id);
      this.dialogCreateDiscussion = true;
      /* this.$nextTick(() => {
        this.$refs['discussionForm'].clearValidate();
      });*/
    },
    async setDiscussionStatus(id, type){
      const data = await discussionResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    async setSubscriptionStatus(id, type){
      const data = await subscriptionResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$notify({
          title: this.$t('general.success'),
          message: this.$t('general.statusUpdated'),
          type: 'success',
        });
        this.initSearch();
      } else {
        this.$notify.error({
          title: this.$t('general.error'),
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteDiscussion(id, visible, name) {
      this.$confirm(this.$t('discussion.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['Admin']) || checkPermission(['delete discussion']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              discussionResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('discussion.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setDiscussionStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createDiscussion() {
      this.$refs['discussionForm'].validate((valid) => {
        if (valid) {
          this.currentDiscussion.roles = [this.currentDiscussion.role];
          this.discussionCreating = true;
          discussionResource
            .store(this.currentDiscussion)
            .then(async(response) => {
              this.$message({
                message: this.$t('discussion.NewDiscussion') + ' ' + this.currentDiscussion.subject + ' ' + this.$t('discussion.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentDiscussionId = response.data.id;
              this.resetNewDiscussion();
              this.dialogCreateDiscussion = false;
              this.handleFilterDiscussions();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.discussionCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    updateDiscussion() {
      this.$refs['discussionForm'].validate((valid) => {
        if (valid) {
          this.discussionCreating = true;
          discussionResource.update(this.currentDiscussion.id, this.currentDiscussion).then(response => {
            this.resetNewDiscussion();
            this.handleFilterDiscussions();
            this.$notify({
              title: this.$t('general.success'),
              message: this.$t('discussion.isupdatedSuccessfully'),
              type: 'success',
            });
            this.dialogCreateDiscussion = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.discussionCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    resetNewDiscussion() {
      this.currentDiscussion = {
        sender: {
          subscription: {},
        },
        receipt: {
          subscription: {},
        },
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('discussion.subject'), this.$t('discussion.reasons')];
        const filterVal = ['id', 'subject', 'reasons'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Discussions list GIFTOLIFE_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>

.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}

</style>
